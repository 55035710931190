<template>
	<div>
		<rxNavBar title="租约变更" androidOrIOSFlag="true"></rxNavBar>
		<!-- 顶部固定区域-->
		<div class="header">
			<!-- 预约切换区域-->
			<div class="choose">
				<div class="choose-one">
					<div :class="condition?'choose-one-textActive':'choose-one-text'" @click="showOnOrder">租客</div>
					<div class="choose-one-icon" v-show="condition"></div>
				</div>
				<div class="choose-line"></div>
				<div class="choose-one">
					<div :class="!condition?'choose-one-textActive':'choose-one-text'" @click="showFinished">业主</div>
					<div class="choose-one-icon" v-show="!condition"></div>
				</div>
			</div>
		</div>
		<!--选择类型测试vant-->
		<div class="outType">
			<div class="type" @click="showPicker = true">{{certificateType.dictionaryTitle}}
				<div class="arrows"></div>
			</div>
		</div>
		<van-popup v-model="showPicker" round position="bottom">
			<van-picker title="选择类型" show-toolbar :columns="columns" @confirm="onConfirm" @cancel="showPicker = false" @change="onChange"
			 value-key="dictionaryTitle" :default-index="defaultIndex" />
		</van-popup>
		<!-- 主体的列表们 -->
		<van-pull-refresh v-model="isLoading" @refresh="onRefresh" pulling-text="下拉即可刷新" success-text="刷新成功">
			<van-list v-model="loading" :finished="finished" finished-text="已经到底了" @load="onLoad">
				<div class="bo-dy" v-if="swi==true">
					<div class="box" v-for=" (item,i) in userLeaseChangeListZ " :key="i">
						<!-- 单一列表的最上部分 （第一部分）-->
						<div class="box-head" @click="www(item.leaseChangeStatus)">
							<!-- 左上角的状态 -->
							<div class="leftStatus3" v-if="item.leaseChangeStatus == '上级已审批' ">
								{{item.leaseChangeStatus}}
							</div>
							<div class="leftStatus1" v-if="item.leaseChangeStatus == '已提交' ">
								{{item.leaseChangeStatus}}
							</div>
							<div class="leftStatus1" v-if="item.leaseChangeStatus == '已签字' ">
								{{item.leaseChangeStatus}}
							</div>
							<div class="leftStatus1" v-if="item.leaseChangeStatus == '申请中' ">
								{{item.leaseChangeStatus}}
							</div>
							<div class="leftStatus2" v-if="item.leaseChangeStatus == '已拒绝' ">
								{{item.leaseChangeStatus}}
							</div>
							<div class="leftStatus3" v-if="item.leaseChangeStatus == '已取消' ">
								{{item.leaseChangeStatus}}
							</div>
							<div class="leftStatus4" v-if="item.leaseChangeStatus == '已审批' ">
								{{item.leaseChangeStatus}}
							</div>
							<!-- 右上角的状态 -->
							<div class="rightStatus2" v-if="item.leaseChangeTypeValue==0||item.leaseChangeTypeValue==1">
								{{item.leaseChangeType}}
							</div>
							<div class="rightStatus1" v-if="item.leaseChangeTypeValue==2 ||item.leaseChangeTypeValue==3">
								{{item.leaseChangeType}}
							</div>
							<div class="rightStatus3" v-if="item.leaseChangeTypeValue==4 ||item.leaseChangeTypeValue==5">
								{{item.leaseChangeType}}
							</div>
						</div>
						<!-- 列表中间的主体部分（第二部分）-->
						<div class="box-body">
							<!-- 左边的图片 -->
							<div class="leftImg" v-if="item.roomPhoto == undefined "> <img src="../../../assets/images/default_img.jpg" alt="">
							</div>
							<div class="leftImg" v-else> <img src="item.roomPhoto" alt="">
							</div>
							<!-- 右侧文字整体 -->
							<div class="mainText">
								<!-- 右侧最上方的粗体文字 -->
								<div class="boldText">{{item.roomAddress}}</div>
								<!-- 第二行的街道，面积，层数 -->
								<div class="oneListTop-text-detail">
									<div class="oneListTop-text-detail-info">{{item.roombusinessCircleName}}</div>
									<div class="oneListTop-text-detail-line"></div>
									<div class="oneListTop-text-detail-info">{{item.roomArea}}㎡</div>
									<div class="oneListTop-text-detail-line"></div>
									<div class="oneListTop-text-detail-info">{{item.roomFloor}}层</div>
								</div>
								<!-- 第三行的时间 -->
								<div v-if="contractType == 0" class="oneListTop-text-detail-info">{{item.contractBeginTime}} 至
									{{item.contractEndTime}}</div>
								<div v-if="contractType == 1" class="oneListTop-text-detail-info">{{item.ownerContractBeginTime}} 至
									{{item.ownerContractEndTime}}</div>
							</div>
						</div>
						<!-- 提交时间、处理时间（第三部分） -->
						<div class="box-time">提交时间：{{item.applicationTime}}</div>
						<!-- 确认交割，查看详情（第四部分） -->
						<div class="box-bottom" v-if="item.leaseChangeStatusValue!='3'||item.isUserComfirm=='0'&&item.leaseChangeStatusValue=='0'||
								item.isUserComfirm=='0'&&item.leaseChangeStatusValue=='1'||
								item.isUserComfirm=='0'&&item.leaseChangeStatusValue=='5'||
								item.isUserComfirm=='0'&&item.leaseChangeStatusValue=='6'||
								item.isUserComfirm=='0'&&item.leaseChangeStatusValue=='7'">
							<div class="seeMore" v-if="item.leaseChangeStatusValue!='3'" @click="changeDetails(item.userLeaseChange_id,item.roomPhoto,item.contractId)">查看详情</div>
							<!-- <span v-if="item.isUserComfirm=='0'"> -->
								<div v-if="item.isUserComfirm=='0'&&item.leaseChangeStatusValue=='0'||
								item.isUserComfirm=='0'&&item.leaseChangeStatusValue=='1'||
								item.isUserComfirm=='0'&&item.leaseChangeStatusValue=='5'||
								item.isUserComfirm=='0'&&item.leaseChangeStatusValue=='6'||
								item.isUserComfirm=='0'&&item.leaseChangeStatusValue=='7'"
								 class="confirmSubmit" @click="propertyDelivery(item.contractId)">
									确认物业交割
								</div>
							<!-- </span> -->
						</div>
					</div>
				</div>

			</van-list>
			<!-- 没有数据时显示 -->
			<div class="empty" v-if="swi==false">
				<img src="../../../assets/images/empty.png" alt="">
				<div class="empryText">啊哦，这里好像什么都没有</div>
			</div>
		</van-pull-refresh>

	</div>

</template>

<script>
	import {
		NavBar,
		Picker,
		List,
		PullRefresh,
		Toast,
		Popup,
	} from "vant";
	Vue.use(Picker);
	Vue.use(List);
	Vue.use(PullRefresh);
	Vue.use(Popup);
	Vue.use(PullRefresh);
	import {
		queryBaseData,
		userLeaseChangeList
	} from "../../../getData/getData";
	import {
		getUserId,
		globaluserId,
		responseUtil
	} from "../../../libs/rongxunUtil";
	import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
	import Vue from 'vue';
	import dialMobile from '../../../components/rongxun/rx-dialMobile/dialMobile'

	export default {
		name: "myOrder",
		data() {
			return {
				columns: [{
					dictionaryTitle: "全部",
					dictionaryValue: "0"
				}],
				show: false,
				showPicker: false,
				condition: true, //用来控制两种状态的切换条件
				isOccupationShow: false,
				loading: false,
				finished: false,
				currentPage: '1', //当前页
				pageCount: '', //总页数
				numberPage: '10', //每页条数
				count: 0,
				certificateType: '',
				isOccupationShow: false,
				defaultIndex: '',
				userLeaseChangeListZ: [], //公用的数据列表
				// userLeaseChangeListY: [], //业主数据列表
				dictionaryValue: "", //租约类型的号（数字）
				contractType: 0, //合同类型(0 租客 1 业主)
				swi: true,
				isLoading: false,
				totalPage: '',
				contract_id: '',
			}
		},
		components: {
			[NavBar.name]: NavBar,
			[List.name]: List,
			[PullRefresh.name]: PullRefresh,
			dialMobile,
			rxNavBar

		},
		beforeRouteEnter(to, from, next) {
			//清除本地localStorage缓存
			if(from.name == null){
				localStorage.removeItem('currentLabel');
				localStorage.removeItem('type');
				localStorage.removeItem('inputValue');
				localStorage.removeItem('currentLabelPublic');
			}
			next()
		},
		created() {
			let contractType = JSON.parse(localStorage.getItem('contractType')); //先读取local里存储的历史记录
			if (contractType !== null) {
				if (contractType !== '') {
					this.contractType = Number(contractType);
				}
			}
			let condition = JSON.parse(localStorage.getItem('condition')); //先读取local里存储的历史记录
			if (contractType !== null) {
				if (contractType !== '') {
					if (condition == 0) {
						this.condition = true
					} else {
						this.condition = false
					}
				}
			}
			this.userLeaseChangeListZ = []
			this.initData();
			this.initDicData();
			// this.userLeaseChangeList();
		},
		//初始化
		mounted() {
			// this.certificateType = this.columns[0]
		},
		methods: {
			//初始化数据
			initData() {
				const that = this
				let initData = {
					user_id: globaluserId()
				}
			},
			www(e) {
				console.log(e)
			},
			//租约初始化接口
			userLeaseChangeList() {
				const that = this
				if (that.dictionaryValue == 0) {
					that.dictionaryValue = ""
				}
				let initData = {}
				initData.user_id = globaluserId() //userid
				initData.leaseChangeType = that.dictionaryValue //租约类型的号（数字）
				initData.contractType = that.contractType //合同类型(0 租客 1 业主)
				initData.currentPage = that.currentPage //当前页
				initData.numberPage = that.numberPage //每页条数
				userLeaseChangeList(initData).then(function(response) {
					responseUtil.dealResponse(that, response, () => {
						console.log(response)
						if (response.data.code == 0) {
							that.pageCount = response.data.data.pageCount //总页数
							var totalPage = response.data.data.totalPage //总条数
							var list = response.data.data.userLeaseChangeList
							if (totalPage == 0 || that.pageCount == 0 || !list) { //没有数据时
								that.loading = false
								that.finished = true
							}
							if (list && that.pageCount != 0) { //有数据时
								var currentPage = parseInt(that.currentPage); //当前页
								var pages = that.pageCount; //总页数
								if (pages >= currentPage) { //当前页不大于总页数
									that.currentPage = currentPage + 1
									for (let i = 0; i < list.length; i++) {
										that.userLeaseChangeListZ.push(list[i])
									}
									that.loading = false
									// console.log("长度=" + that.userLeaseChangeListZ.length)
								} else {
									that.loading = false
									that.finished = true
								}
							}
						} else if (response.data.code != 0) {
							responseUtil.alertMsg(that, response.data.msg)
						} else {
							responseUtil.alertMsg(that, response.data.msg)
						}
						if (that.userLeaseChangeListZ.length == 0) {
							that.swi = false
						} else {
							that.swi = true
						}


					})
				})
			},
			//初始化字典数据
			initDicData: function() {
				const that = this
				let initData = {}
				initData.fdName = ["LEASECHANGETYPE"]
				queryBaseData(initData).then(function(response) {
					responseUtil.dealResponse(that, response, () => {
						// that.columns = response.data.data.LEASECHANGETYPE
						that.certificateType = that.columns[0]
						that.totalPage = response.data.data.totalPage //总条数
						that.pageCount = response.data.data.pageCount //总页数
						// console.log(that.columns)
						for (var i = 0; i < response.data.data.LEASECHANGETYPE.length; i++) {
							that.columns.push(response.data.data.LEASECHANGETYPE[i])
						}
						// console.log(that.columns)
					})
				})
			},
			onConfirm(value, index) {
				this.certificateType = value
				this.showPicker = false
				this.defaultIndex = index
				if (value.dictionaryValue == 1) {
					this.dictionaryValue = 2
				} else if (value.dictionaryValue == 2) {
					this.dictionaryValue = 1
				} else {
					this.dictionaryValue = value.dictionaryValue //租约类型的号（数字）
				}
				this.currentPage = 0
				this.userLeaseChangeListZ = []
				this.userLeaseChangeList();
				// Toast(this.dictionaryValue)
				// Toast(`当前值：${value.dictionaryValue}, 当前索引：${index}`);
			},
			onChange(picker, value, index) {
				// Toast(`当前值：${value.dictionaryValue}`)
			},
			onCancel() {

			},
			showPopup() {
				this.show = true;
			},
			//点击下拉框区域外 下拉框隐藏
			clickOccupationShow() {
				this.isOccupationShow = !this.isOccupationShow;
				var panel = document.getElementById('occupationPanel')
				if (panel) {
					document.addEventListener('click', e => {
						if (!panel.contains(e.target)) {
							this.isOccupationShow = false
						}
					})
				}

			},
			//点击下拉框选项 进行数据绑定
			selectOccupationOption(item) {
				this.occupation = this.occupationArr[item].dictionaryTitle
				this.isOccupationShow = false

			},
			//租客租约
			showOnOrder() {
				var that = this
				that.userLeaseChangeListZ = []
				that.condition = true
				this.contractType = "0"
				this.default();
				this.onLoad();
				this.userLeaseChangeList();
				localStorage.setItem('contractType', JSON.stringify(0))
				localStorage.setItem('condition', JSON.stringify(0))
			},
			//业主租约
			showFinished() {
				var that = this
				that.userLeaseChangeListZ = []
				that.condition = false
				this.contractType = "1"
				this.default();
				this.onLoad();
				this.userLeaseChangeList();
				localStorage.setItem('contractType', JSON.stringify(1))
				localStorage.setItem('condition', JSON.stringify(1))
			},
			//返回上一页
			leftReturn() {
				this.$router.go(-1)
			},
			//分页
			onLoad() {
				this.userLeaseChangeList();
			},
			onRefresh() { //刷新
				setTimeout(() => {
					this.default();
					this.isLoading = false;
					this.userLeaseChangeListZ = []
					this.initData();
					// this.initDicData();
					this.userLeaseChangeList();
					this.onLoad();
					// Toast(this.msg);
				}, 800);
			},
			default () { //默认值
				this.pageCount = ''
				this.currentPage = '1'
			},
			//物业交割单
			propertyDelivery(e) {
				let that = this
				this.contract_id = e
				console.log(this.contract_id)
				this.$router.push({
					name: 'propertyDelivery',
					query: {
						contract_id: that.contract_id,
						swi: '1'
					}
				})
			},
			//变更详情页面
			changeDetails(e, ee, contractId) {
				this.$router.push({
					name: 'changeDetails',
					query: {
						userLeaseChange_id: e,
						roomPhoto: ee,
						contractId: contractId,
						leaseType: this.contractType
					}
				})
			},
		},

	}
</script>

<style scoped>
	.navbar {
		background-color: white;
		border-bottom: 2px solid white;
	}

	.header {
		width: 100%;
		height: auto;
		position: fixed;
		z-index: 8;
		top: 52px;
		background-color: #f8f8f8;
	}

	.choose {
		width: 100%;
		height: auto;
		background-color: white;
		display: flex;
	}

	.choose-one {
		width: 187px;
		text-align: center;
	}

	.choose-one-textActive {
		width: 42px;
		height: 20px;
		line-height: 20px;
		font-size: 14px;
		font-weight: bold;
		margin-top: 24px;
		margin-left: 72px;
		color: rgba(255, 93, 59, 1);
		text-align: center;
		font-family: PingFangSC-Semibold;
	}

	.choose-one-text {
		width: 42px;
		height: 20px;
		line-height: 20px;
		font-size: 14px;
		margin-top: 24px;
		margin-left: 72px;
		color: rgba(153, 153, 153, 1);
		text-align: center;
		font-family: PingFangSC-Semibold;
	}

	.choose-one-icon {
		width: 20px;
		height: 4px;
		border-radius: 3px;
		background-color: rgba(255, 93, 59, 1);
		margin: 5px 0 15px 83px;
	}

	.choose-line {
		width: 2px;
		height: 17px;
		margin-top: 23px;
		background-color: rgba(239, 239, 239, 1);
	}

	.orderList {
		width: 100%;
		height: auto;
		position: relative;
		top: 120px;
	}

	.type {
		top: 110px;
		padding-left: 20px;
		color: rgba(255, 93, 59, 1);
		font-size: 12px;
		position: fixed;
		z-index: 99;
		display: block;
		width: 100%;
		background-color: rgb(248, 248, 248);
		/* background-color: red; */
		height: 45px;
		line-height: 45px;
	}

	::v-deep .van-pull-refresh__head {
		height: 0%;
		width: 0%;
	}

	.arrows {
		height: 0;
		width: 0;
		border: 3.5px solid;
		border-color: black transparent transparent transparent;
		margin-right: 90%;
		float: right;
		margin-top: 5.6%;
	}

	.bo-dy {
		margin-top: 104px;
	}

	.box {
		width: 345px;
		background-color: white;
		border-radius: 8px;
		margin: 0 auto;
		margin-bottom: 17px;
	}

	.box-head {
		height: 37px;
		border-bottom: 1px solid rgba(239, 239, 239, 1);
	}

	.leftStatus1,
	.leftStatus2,
	.leftStatus3,
	.leftStatus4 {
		/* width: 43px; */
		padding-left: 5px;
		padding-right: 5px;
		height: 16px;
		line-height: 16px;
		;
		color: rgba(255, 255, 255, 1);
		font-size: 10px;
		text-align: center;
		float: left;
		border-top-left-radius: 8px;
		border-bottom-right-radius: 8px;
	}

	.leftStatus1 {
		background-image: linear-gradient(to right, #66CB94, #28D3B0);
	}

	.leftStatus2 {
		background-image: linear-gradient(to right, #E02020, #FF431B);
	}

	.leftStatus3,
	.leftStatus4 {
		background-image: linear-gradient(to right, #999999, #999999);
	}

	.rightStatus1,
	.rightStatus2,
	.rightStatus3 {
		float: right;
		font-size: 12px;
		font-weight: 600;
		margin-right: 15px;
		margin-top: 9px;
	}

	.rightStatus1 {
		color: #E02020;
	}

	.rightStatus2 {
		color: #3191FF;
	}

	.rightStatus3 {
		color: #FF5D3B;
	}

	.box-body {
		height: 104px;
		border-bottom: 1px solid rgba(239, 239, 239, 1);
	}

	.leftImg img {
		margin-left: 10px;
		margin-top: 15px;
		width: 100px;
		height: 75px;
		border-radius: 8px;
		float: left;
	}

	.mainText {
		float: left;
		width: 210px;
		height: 70px;
		margin-left: 8px;
		margin-top: 16px;
	}

	.boldText {
		font-size: 15px;
		font-weight: 700;
		width: 220px;
		white-space: nowrap;
		/*内容超宽后禁止换行显示*/
		overflow: hidden;
		/*超出部分隐藏*/
		text-overflow: ellipsis;
		/*文字超出部分以省略号显示*/
	}

	.oneListTop-text-detail {
		height: 17px;
		margin-top: 5px;
		color: rgba(102, 102, 102, 1);
		font-size: 12px;
		text-align: left;
		font-family: PingFangSC-Regular;
		display: flex;
		align-items: center;
		margin-bottom: 5px;
	}

	.oneListTop-text-detail-line {
		width: 1px;
		height: 12px;
		background-color: rgba(102, 102, 102, 1);
		margin: 0 10px;
	}

	.oneListTop-text-detail-info {
		color: #666666;
		font-size: 12px;
		font-weight: 500;
	}

	.box-time {
		color: rgba(153, 153, 153, 1);
		font-size: 12px;
		padding-left: 10px;
		height: 50px;
		line-height: 50px;
	}

	.box-bottom {
		height: 60px;
		border-top: 1px solid rgba(239, 239, 239, 1);
	}

	.seeMore {
		width: 80px;
		height: 30px;
		border-radius: 8px;
		border: 1px solid transparent;
		border-radius: 8px;
		/* 圆角属性测试 */
		background-image: linear-gradient(white, white),
			/* 底色，即原有的背景 */
			linear-gradient(to right, #FFC884, #FF9668, #FF7857);
		/* 模拟渐变边框 */
		background-clip: padding-box, border-box;
		background-origin: border-box;
		line-height: 30px;
		margin-right: 12px;
		color: rgba(255, 93, 59, 1);
		font-size: 12px;
		text-align: center;
		font-family: PingFangSC-Regular;
		float: right;
		margin-top: 15px;
	}

	.confirmSubmit {
		width: 95px;
		height: 30px;
		line-height: 30px;
		border-radius: 8px;
		background: -webkit-linear-gradient(left, #FFC274, #FF5D3B);
		box-shadow: 0px 1px 1px 0px rgba(188, 0, 0, 0.2);
		margin-right: 12px;
		color: rgba(255, 255, 255, 1);
		font-size: 12px;
		text-align: center;
		font-family: PingFangSC-Regular;
		float: right;
		margin-top: 15px;
	}

	.empty {
		margin-top: 180px;
		text-align: center;
	}

	.empty img {
		width: 85px;
	}

	.empryText {
		text-align: center;
		color: #ACACAC;
		color: rgba(172, 172, 172, 1);
		font-size: 12px;
		margin-top: 50px;
	}
</style>
